import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ModalsModule from "@/store/modules/ModalsModule";
import OrdersModule from "@/store/modules/OrdersModule";
import CustomerModule from "@/store/modules/CustomerModule";
import PartnerModule from "@/store/modules/PartnerModule";
import TrancheModule from "@/store/modules/TrancheModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ModalsModule,
    OrdersModule,
    CustomerModule,
    PartnerModule,
    ConfigModule,
    TrancheModule,
  },
});

export default store;
