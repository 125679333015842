import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Modal {
  account: string;
  order: any;
  purchase_help: string;
}

interface StoreInfo {
  modals: Modal;
}

@Module
export default class ModalsModule extends VuexModule implements StoreInfo {
  modals = {} as Modal;

  get accountModal(): string {
    return this.modals.account;
  }

  get orderModal(): string {
    return this.modals.order;
  }

  get purchaseModal(): string {
    return this.modals.purchase_help;
  }

  @Mutation
  [Mutations.SET_MODAL_MUTATION](payload) {
    this.modals[payload.target] = payload.value;
  }

  @Action
  [Actions.SET_MODAL_ACTION](payload) {
    this.context.commit(Mutations.SET_MODAL_MUTATION, payload);
  }
}
