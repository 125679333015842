import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface Partner {
  Address: string;
  ContractItem: string;
  PartnerId: string;
  PartnerName: string;
  SalesRepreId: string[];
}

interface Representative {
  SalesRepreID: string;
  SalesRepreFullName: string;
  SalesRepreMobile: string;
  SalesRepreEmail: string;
  SalesReprePicIDDocument: string;
}

interface PartnerInfo {
  partner: Partner;
  representative: Representative;
}

@Module
export default class PartnerModule extends VuexModule implements PartnerInfo {
  partner = {} as Partner;
  representative = {} as Representative;
  partners = [];

  get currentPartner(): Partner {
    return this.partner;
  }

  get currentRepre(): Representative {
    return this.representative;
  }

  get partnersList() {
    return this.partners;
  }

  @Mutation
  [Mutations.SET_PARTNERS_MUTATION](payload) {
    this.partners = payload;
  }

  @Mutation
  [Mutations.SET_PARTNER_MUTATION](payload) {
    this.partner = payload;
  }

  @Mutation
  [Mutations.SET_REPRESENTATIVE_MUTATION](payload) {
    this.representative = payload;
  }

  @Action
  [Actions.SET_PARTNERS_ACTION](data) {
    this.context.commit(Mutations.SET_PARTNERS_MUTATION, data);
  }

  @Action
  [Actions.SET_PARTNER_ACTION](payload) {
    this.context.commit(Mutations.SET_PARTNER_MUTATION, payload);
  }

  @Action
  [Actions.SET_REPRESENTATIVE_ACTION](payload) {
    this.context.commit(Mutations.SET_REPRESENTATIVE_MUTATION, payload);
  }
}
