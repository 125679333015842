const ID_TOKEN_KEY = "id_token" as string;
const INA_TOKEN_KEY = "ina_token" as string;

const AUTH_TOKEN_ACCESS = "auth_token_access" as string;
const AUTH_TOKEN_REFRESH = "auth_token_refresh" as string;
const AUTH_TOKEN_TYPE = "auth_token_type" as string;
const AUTH_TOKEN_EXPIRED = "auth_token_expired" as string;
const AUTH_USER = "auth_user" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUser = (): string | null => {
  const user = localStorage.getItem(AUTH_USER);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const getAccessToken = (): string | null => {
  const type = window.localStorage.getItem(AUTH_TOKEN_TYPE);
  const token = window.localStorage.getItem(AUTH_TOKEN_ACCESS);
  return type + " " + token;
};

export const getInaToken = (): string | null => {
  return window.localStorage.getItem(INA_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveAccessToken = (token: {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: string;
}): void => {
  window.localStorage.setItem(AUTH_TOKEN_ACCESS, token.access_token);
  window.localStorage.setItem(AUTH_TOKEN_REFRESH, token.refresh_token);
  window.localStorage.setItem(AUTH_TOKEN_TYPE, token.token_type);
  window.localStorage.setItem(AUTH_TOKEN_EXPIRED, token.expires_in);
};

export const saveUser = (user: string): void => {
  window.localStorage.setItem(AUTH_USER, JSON.stringify(user));
};

export const saveInaToken = (token: string): void => {
  window.localStorage.setItem(INA_TOKEN_KEY, token);
};

export const setInaToken = (token: string): void => {
  window.localStorage.setItem(INA_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};
export const destroyUser = (): void => {
  window.localStorage.removeItem(AUTH_USER);
  window.localStorage.removeItem(AUTH_TOKEN_ACCESS);
  window.localStorage.removeItem(AUTH_TOKEN_REFRESH);
  window.localStorage.removeItem(AUTH_TOKEN_TYPE);
  window.localStorage.removeItem(AUTH_TOKEN_EXPIRED);
};

export const destroyInaToken = (): void => {
  window.localStorage.removeItem(INA_TOKEN_KEY);
};

export default {
  getToken,
  getInaToken,
  getAccessToken,
  saveToken,
  saveInaToken,
  destroyToken,
  destroyInaToken,
  saveAccessToken,
  saveUser,
  getUser,
  destroyUser,
};
