import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Orders {
  list: Array<string>;
}

interface StoreInfo {
  orders: Orders;
}

@Module
export default class OrdersModule extends VuexModule implements StoreInfo {
  orders = {} as Orders;

  get ordersList(): Array<string> {
    return this.orders.list;
  }

  @Mutation
  [Mutations.SET_ORDERS_MUTATION](payload) {
    this.orders.list = payload;
  }

  @Mutation
  [Mutations.ADD_ORDER_MUTATION](payload) {
    this.orders.list.push(payload);
  }

  @Mutation
  [Mutations.UPDATE_ORDER_MUTATION](payload) {
    this.orders.list[payload.index] = payload.item;
  }

  @Mutation
  [Mutations.REMOVE_ORDER_MUTATION](payload) {
    this.orders.list.splice(payload, 1);
  }

  @Mutation
  [Mutations.CLEAR_SELECT_ORDERS_MUTATION](payload) {
    for (let i = 0; i < payload.length; i++) {
      const index = this.orders.list.indexOf(payload[i]);
      if (index >= 0) {
        this.orders.list.splice(index, 1);
      }
    }
  }

  @Action
  [Actions.SET_ORDERS_ACTION](payload) {
    this.context.commit(Mutations.SET_ORDERS_MUTATION, payload);
  }

  @Action
  [Actions.ADD_ORDER_ACTION](payload) {
    this.context.commit(Mutations.ADD_ORDER_MUTATION, payload);
  }

  @Action
  [Actions.UPDATE_ORDER_ACTION](payload) {
    this.context.commit(Mutations.UPDATE_ORDER_MUTATION, payload);
  }

  @Action
  [Actions.REMOVE_ORDER_ACTION](payload) {
    this.context.commit(Mutations.REMOVE_ORDER_MUTATION, payload);
  }

  @Action
  [Actions.CLEAR_SELECT_ORDERS_ACTION](payload) {
    this.context.commit(Mutations.CLEAR_SELECT_ORDERS_MUTATION, payload);
  }
}
