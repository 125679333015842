import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  UserName: string;
  InaToken: string;
  Partners: string[];
}

export interface Token {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  access_token: Token;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  access_token = {} as Token;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    /*
    console.warn({
      token: !!JwtService.getToken(),
      isAuth: this.isAuthenticated,
    });
    */

    // return !!JwtService.getToken();
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    if (data != null) {
      this.isAuthenticated = true;
      this.user = data;
    }
  }

  @Mutation
  [Mutations.SET_TOKEN](token) {
    this.access_token = token;
    this.errors = {};
    JwtService.saveAccessToken(token);
    ApiService.setAccessHeader();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyUser();
  }

  @Action
  [Actions.LOGIN](credentials) {
    if (JwtService.getAccessToken()) {
      //ApiService.setAccessHeader();
      credentials.data.UserName = credentials.UserName;
      JwtService.saveUser(credentials.data);
      this.context.commit(Mutations.SET_AUTH, credentials.data);
    }
  }

  @Action
  [Actions.TOKEN](credentials) {
    return ApiService.post("/api/Token", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TOKEN, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getAccessToken()) {
      ApiService.setAccessHeader();
      if (payload) {
        ApiService.setInaHeader(payload.InaToken);
      }
      this.context.commit(Mutations.SET_AUTH, payload);
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
