import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Tranche {
  ContCommodity: string;
  ContId: string;
  ContName: string;
  Items: string[];
  TRANCHE_CURRENCY: string;
  YEAR: string;
}

interface TrancheInfo {
  tranche: Tranche;
}

@Module
export default class TrancheModule extends VuexModule implements TrancheInfo {
  tranche = {
    ContCommodity: "",
    ContId: "",
    ContName: "",
    Items: [],
    TRANCHE_CURRENCY: "",
    YEAR: "",
  } as Tranche;

  get currentTranche(): Tranche {
    return this.tranche;
  }

  @Mutation
  [Mutations.SET_TRANCHE_MUTATION](payload) {
    this.tranche = payload;
  }

  @Mutation
  [Mutations.ADD_TRANCHE_MUTATION](payload) {
    this.tranche.Items.push(payload);
  }

  @Mutation
  [Mutations.UPDATE_TRANCHE_MUTATION](payload) {
    this.tranche.Items[payload.index] = payload.item;
  }

  @Mutation
  [Mutations.REMOVE_TRANCHE_MUTATION](payload) {
    this.tranche.Items.splice(payload, 1);
  }

  @Action
  [Actions.SET_TRANCHE_ACTION](payload) {
    this.context.commit(Mutations.SET_TRANCHE_MUTATION, payload);
  }

  @Action
  [Actions.ADD_TRANCHE_ACTION](payload) {
    this.context.commit(Mutations.ADD_TRANCHE_MUTATION, payload);
  }

  @Action
  [Actions.UPDATE_TRANCHE_ACTION](payload) {
    this.context.commit(Mutations.UPDATE_TRANCHE_MUTATION, payload);
  }

  @Action
  [Actions.REMOVE_TRANCHE_ACTION](payload) {
    this.context.commit(Mutations.REMOVE_TRANCHE_MUTATION, payload);
  }
}
