import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Customer {
  PartnerId: string;
  CustomerAccounts: string[];
  RoleCode: string;
}

interface StoreInfo {
  customer: Customer;
}

@Module
export default class CustomerModule extends VuexModule implements StoreInfo {
  customer = {} as Customer;

  get customerId(): string {
    return this.customer.PartnerId;
  }

  get customerName(): string {
    return this.customer.PartnerId;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_MUTATION](payload) {
    this.customer = payload;
  }

  @Action
  [Actions.SET_CUSTOMER_MUTATION](payload) {
    this.context.commit(Mutations.SET_CUSTOMER_MUTATION, payload);
  }
}
