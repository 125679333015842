const filters = {
  dateFormat(value: Date) {
    const date = new Date(value);
    const d = date.getDate().toString();
    const m = (date.getMonth() + 1).toString();
    const y = date.getFullYear();
    return d.padStart(2, "0") + "." + m.padStart(2, "0") + "." + y;
  },
  dateMonth(date: Date) {
    const d = new Date(date);
    return d.getMonth() + 1;
  },
  priceFormat(value: any, rounded: number) {
    if (value) {
      const number = Math.round(value * rounded) / rounded;
      const str = number.toString();
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");
    }
    return value;
  },
  rounded(value: number, rounded: number) {
    if (value) {
      return Math.round(value * rounded) / rounded;
    }
    return value;
  },
  amountFormat(value: number) {
    if (value) {
      if (value < 1) {
        const num = Math.round(value * 100) / 100;
        return num.toString().replace(".", ",");
      } else {
        const num = Math.round(value);
        return num.toString().replace(".", ",");
      }
    }
    return value;
  },
  testFormat(value: any) {
    if (value) {
      const str = value.toString();
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return value;
  },
};
export default filters;
