enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  TOKEN = "token",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_CUSTOMER_MUTATION = "setCustomerAction",
  SET_MODAL_ACTION = "setModalAction",
  SET_ORDERS_ACTION = "SetOrdersAction",
  ADD_ORDER_ACTION = "AddOrderAction",
  UPDATE_ORDER_ACTION = "UdateOrderAction",
  REMOVE_ORDER_ACTION = "RemoveOrderAction",
  CLEAR_SELECT_ORDERS_ACTION = "ClearSelectOrdersAction",
  SET_PARTNERS_ACTION = "SetPartnersAction",
  SET_PARTNER_ACTION = "SetPartnerAction",
  SET_TRANCHE_ACTION = "SetTrancheAction",
  SET_REPRESENTATIVE_ACTION = "SetRepresentativeAction",
  ADD_TRANCHE_ACTION = "AddTrancheAction",
  UPDATE_TRANCHE_ACTION = "UdateTrancheAction",
  REMOVE_TRANCHE_ACTION = "RemoveTrancheAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_TOKEN = "setToken",
  SET_USER = "setUser",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_CUSTOMER_MUTATION = "setCustomerMutation",
  SET_MODAL_MUTATION = "setModalMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_ORDERS_MUTATION = "SetOrdersMutation",
  ADD_ORDER_MUTATION = "AddOrderMutation",
  UPDATE_ORDER_MUTATION = "UpdateOrderMutation",
  REMOVE_ORDER_MUTATION = "RemoveOrderMutation",
  CLEAR_SELECT_ORDERS_MUTATION = "ClearSelectOrdersMutation",
  SET_PARTNERS_MUTATION = "SetPartnersMutation",
  SET_PARTNER_MUTATION = "SetPartnerMutation",
  SET_TRANCHE_MUTATION = "SetTrancheMutation",
  SET_REPRESENTATIVE_MUTATION = "SetRepresentativeMutation",
  ADD_TRANCHE_MUTATION = "AddTrancheMutation",
  UPDATE_TRANCHE_MUTATION = "UpdateTrancheMutation",
  REMOVE_TRANCHE_MUTATION = "RemoveTrancheMutation",
}

export { Actions, Mutations };
