import { createI18n } from "vue-i18n";

const messages = {
  cs: {
    account: "Správa účtu",
    sign_out: "Odhlásit se",
    sign_in: "Přihlášení",
    sign_in_btn: "Přihlásit se",
    continue: "Pokračovat",
    email: "Email",
    your_email: "Váš email",
    password: "Heslo",
    forgot_password: "Zapomenuté heslo",
    old_password: "Stávajíci heslo",
    new_password: "Nové heslo",
    confirm_password: "Potvrzení hesla",
    back_to_login: "Zpět na přihlášení",
    send_btn: "Odeslat",
    understand_btn: "Rozumím",
    please_wait: "Vyčkejte",
    development: "Vývoj trhu",
    purchase: "Nákup",
    consumption: "Odběrový diagram",
    orders: "Objednávky",
    customer: "Zákazník",
    establishment: "Provozovna",
    order: "Objednávka",
    delivery: "Dodávka",
    tariff: "Tarif",
    commodity: "Komodita",
    date: "Datum",
    price: "Cena",
    amount: "Množství",
    state: "Stav",
    approved: "Schváleno",
    processing: "Zpracovává se",
    denied: "Zamítnuto",
    description: "Popis",
    date_start: "Zahájení",
    date_end: "Ukončení",
    password_setting: "Nastavení hesla",
    convert_btn: "Přepočítat",
    order_btn: "Objednat",
    delete_rejected_btn: "Smazat zamítnuté",
    cancel_changes_btn: "Zrušit změny",
    order_confirmation: "Potvrzení objednávky",
    period: "Období",
    tranche_size: "Velikost Tranše",
    price_per_unit: "Cena  za jednotku",
    total_price: "Celková cena",
    summary_of_tranches: "Souhrn nepotvrzených tranší",
    confirm_btn: "Potvrdit",
    sms_code: "Zadejte SMS Kód, který byl odeslán na ******123",
    resend_code_btn: "Zaslat SMS znovu",
    gas: "Plyn",
    electricity: "Elektřina",
    price_base_load: "Ceny produktu Base load na následující rok",
    average_price: "Průměrná cena spotového produktu od začátku roku",
    product: "Produkt",
    difference_from_currnet: "Rozdíl oproti aktuální uzavírací ceně BL 2023",
    operating_room: "Provozovna",
    profile: "Profil",
    consumption_table: "Tabulka spotřeby v MWh",
    consumption_label: "Spotřeba",
    days: "Dny",
    months: "Měsíce",
    course_label: "Kurz",
    negotiated_volume_label: "Sjednaný objem",
    period_label: "Období",
    your_price_for_label: "Vaše cena za MWh",
    closed_position_label: "Uzavřená pozice",
    open_position_label: "Při uzavření otevřené pozice",
    operating_room_label: "Provozovna",
    profile_label: "Profil",
    input_required: "Pole musí být vyplňeno!",
    purchase_help: "Jak nakupovat",
    search: "Vyhledat...",
    no_results: "Nic nenalezeno...",
    currency_label: "Měna",
    page_not_found: "Stránka nenalezena...",
    back_home: "Zpět na domovskou stránku",
    no_orders: "Momentálně zde nemáte žádné objednávky.",
    month_january: "Leden",
    month_february: "Únor",
    month_march: "Březen",
    month_april: "Duben",
    month_may: "Květen",
    month_june: "Červen",
    month_july: "Červenec",
    month_august: "Srpen",
    month_september: "Září",
    month_october: "Říjen",
    month_november: "Listopad",
    month_december: "Prosinec",
    no_price: "Cena není k dispozici",
    product_name: "Nákup profi",
    pass_match: "Hesla se musí shodovat",
    retry_btn: "Znovu",
    email_valid: "Pole musí být email",
    reset_email_message: "Na váš email byl odeslán potvrzovací kód.",
    order_success_message: "Objednávka byla přijata a čeká na schválení.",
    order_error_message:
      "Něco se pokazilo, kontaktujte prosím svého obchodního zástupce!",
    password_confirm_message: "Vaše heslo bylo změněno",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "cs",
  globalInjection: true,
  messages,
});

export default i18n;
